<template>
  <b-modal variant="light" v-model="modalCreate" no-fade size="xl" id="create-bill" ref="create-bill" :no-close-on-backdrop="true" hide-footer hide-header hide-backdrop>
    <div class="row">
      <div class="col-md-12 stretch-card">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-10">
                <h4 class="card-title mb-4">Agregar Factura de Gasto</h4>
              </div>
              <div class="col-2">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="reset">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
            </div>
            <div class="row pt-4 mb-3">
              <div class="col-12 col-lg-4">
                <div class="form-group">
                  <label for="provider_id">Proveedor</label>
                  <v-select
                    v-model="form.provider_id"
                    :options="providersDropdown"
                    id="provider_id"
                    :reduce="r => r.code"
                    :placeholder="'Selecciona un proveedor'"
                  >
                    <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching">
                        No se encontraron resultados para <em>{{ search }}</em>.
                      </template>
                      <em style="opacity: 0.5;" v-else>
                        Empiece a escribir para buscar.
                      </em>
                    </template>
                  </v-select>
                  <div v-if="errors.hasOwnProperty('provider_id')" class="text-left font-weight-bold text-danger mb-4 mt-2">
                    <small>{{errors.provider_id[0]}}</small>
                  </div>
                </div>
              </div>
              <div class="col-12 col-lg-4">
                <div class="form-group">
                  <label for="type_id">Tipo</label>
                  <select v-model="form.type_id" name="type_id" id="type_id" class="form-control form-control-sm">
                    <option value="1">Factura para consumidor final</option>
                    <option value="2">Factura de crédito fiscal</option>
                    <option value="3">Régimen especial de tributación</option>
                    <option value="4">Factura gubernamental</option>
                    <option value="5">Proforma</option>
                  </select>
                  <div v-if="errors.hasOwnProperty('type_id')" class="text-left font-weight-bold text-danger mb-4 mt-2">
                    <small>{{errors.type_id[0]}}</small>
                  </div>
                </div>
              </div>
              <div class="col-12 col-lg-4">
                <div class="form-group">
                  <label for="tax_receipt_number">Número de Comprobante</label>
                  <input v-model="form.tax_receipt_number" type="text" id="tax_receipt_number" class="form-control">
<!--                  <datetime v-model="form.due_date" :config="dateConfig" id="due_date" class="form-control"></datetime>-->
                  <div v-if="errors.hasOwnProperty('tax_receipt_number')" class="text-left font-weight-bold text-danger mb-4 mt-2">
                    <small>{{errors.tax_receipt_number[0]}}</small>
                  </div>
                </div>
              </div>
              <div class="col-12 col-lg-4">
                <div class="form-group">
                  <label for="tax_receipt_number">Número de Orden</label>
                  <input v-model="form.order_number" type="text" id="order_number" class="form-control">
                  <div v-if="errors.hasOwnProperty('order_number')" class="text-left font-weight-bold text-danger mb-4 mt-2">
                    <small>{{errors.order_number[0]}}</small>
                  </div>
                </div>
              </div>
              <div class="col-12 col-lg-4">
                <div class="form-group">
                  <label for="tax_receipt_number">Fecha Factura</label>
                  <datetime v-model="form.bill_date" :config="dateConfig" id="bill_date" class="form-control"></datetime>
                  <div v-if="errors.hasOwnProperty('bill_date')" class="text-left font-weight-bold text-danger mb-4 mt-2">
                    <small>{{errors.bill_date[0]}}</small>
                  </div>
                </div>
              </div>
              <div class="col-12 col-lg-4">
                <div class="form-group">
                  <label for="tax_receipt_number">Fecha Vencimiento</label>
                  <datetime v-model="form.due_date" :config="dateConfig" id="due_date" class="form-control"></datetime>
                  <div v-if="errors.hasOwnProperty('due_date')" class="text-left font-weight-bold text-danger mb-4 mt-2">
                    <small>{{errors.due_date[0]}}</small>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mb-5">
              <div class="col-12 ">
                <div class="table-responsive mb-5">
                  <table class="table table-hover">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Cantidad</th>
                        <th scope="col">Descripción</th>
                        <th scope="col">Monto</th>
                        <th scope="col">Subtotal</th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in form.items" :key="'item-'+index">
                        <th scope="row">{{ index + 1 }}</th>
                        <td>
                          <input v-model="item.qty" type="number" class="form-control" :class="errors.hasOwnProperty('items.'+index+'.qty') ? 'is-invalid' : ''" min="1" value="1" />
                        </td>
                        <td>
                          <input v-model="item.description" type="text" class="form-control" :class="errors.hasOwnProperty('items.'+index+'.description') ? 'is-invalid' : ''" />
                        </td>
                        <td>
                          <input v-model="item.amount" type="number" class="form-control" :class="errors.hasOwnProperty('items.'+index+'.amount') ? 'is-invalid' : ''">
                        </td>
                        <td>
                          <input type="text" class="form-control disabled" :value="((item.qty * item.amount) ? item.qty * item.amount : 0) | formatMoney">
                        </td>
                        <td>
                          <button @click.prevent="removeItem(index)" class="btn btn-sm btn-danger">Eliminar</button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="col-12 text-center">
                <button class="btn btn-gradient-info btn-sm" @click.prevent="addItem">
                  <i class="mdi mdi-plus"></i>
                </button>
              </div>
            </div>
            <div class="row">
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label for="notes">Notas</label>
                  <textarea v-model="form.notes" name="notes" id="notes" cols="15" rows="5" class="form-control"></textarea>
                  <div v-if="errors.hasOwnProperty('notes')" class="text-left font-weight-bold text-danger mb-4 mt-2">
                    <small>{{errors.notes[0]}}</small>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6 text-right pt-3">
                <div class="row mb-2">
                  <div class="col-6">
                    <span class="font-weight-bold">Subtotal</span>
                  </div>
                  <div class="col-6">
                    <span>{{ subtotal | formatMoney }}</span>
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col-6">
                    <span class="font-weight-bold">Itbis</span>
                  </div>
                  <div class="col-6">
                    <span>{{ itbis | formatMoney }}</span>
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col-6">
                    <span class="font-weight-bold">Total</span>
                  </div>
                  <div class="col-6">
                    <span>{{ total | formatMoney }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="row justify-content-end">
              <div class="col-auto">
                <button @click.prevent="createResource" class="btn btn-gradient-info btn-md">Guardar</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>
<script>
import { mapMutations, mapGetters, mapActions } from 'vuex'
import { Spanish } from 'flatpickr/dist/l10n/es.js'
import ConfirmDatePlugin from 'flatpickr/dist/plugins/confirmDate/confirmDate.js'

export default {
  name: 'CreateBill',
  data () {
    return {
      form: {
        provider_id: '',
        type_id: '',
        tax_receipt_number: '',
        order_number: '',
        bill_date: '',
        due_date: '',
        items: [{
          id: '',
          qty: 1,
          description: '',
          amount: 0
        }],
        notes: '',
        discount: '',
        itbis: '',
        subtotal: '',
        total: '',
        status: 0
      },
      errors: {},
      dateConfig: {
        altInput: true,
        enableTime: false,
        plugins: [new ConfirmDatePlugin({
          confirmIcon: '<i class="fa fa-check"></i>',
          confirmText: 'OK ',
          showAlways: false
        })],
        altFormat: 'F j, Y',
        dateFormat: 'Y-m-d',
        time_24hr: false,
        locale: Spanish // locale for this instance only,
      }
    }
  },
  methods: {
    ...mapMutations({
      TOGGLE_MODAL_CREATE: 'Bill/TOGGLE_MODAL_CREATE'
    }),
    ...mapActions({
      create: 'Bill/create'
    }),
    createResource () {
      this.form.itbis = this.itbis
      this.form.subtotal = this.subtotal
      this.form.total = this.total
      this.create(this.form)
        .then(() => {
          this.reset()
        })
        .catch((error) => {
          this.errors = error.response.data.errors
          // if (!error.response.data.errors) {
          this.$swal(error.response.data.message, '', 'error')
          // }
        })
    },
    reset () {
      this.form = {
        provider_id: '',
        type_id: '',
        tax_receipt_number: '',
        order_number: '',
        bill_date: '',
        due_date: '',
        items: [],
        notes: '',
        discount: '',
        itbis: this.itbis,
        subtotal: this.subtotal,
        total: this.total,
        status: 0
      }
      this.errors = {}
      this.TOGGLE_MODAL_CREATE(false)
      this.$events.fire('refresh-table')
    },
    addItem () {
      this.form.items.push({
        id: '',
        qty: 1,
        description: '',
        amount: 0
      })
    },
    removeItem (index) {
      this.$delete(this.form.items, index)
    }
  },
  computed: {
    ...mapGetters({
      modalCreate: 'Bill/modalCreate',
      providersDropdown: 'Provider/providers_dropdown'
    }),
    subtotal () {
      const subtotal = this._.sumBy(this.form.items, function (o) {
        if (o.amount > 0) {
          return (parseFloat(o.amount) * parseInt(o.qty))
        }
      })
      return subtotal > 0 ? subtotal : 0
    },
    itbis () {
      const itbis = this.subtotal * parseFloat(0.18)
      return itbis > 0 ? itbis : 0
    },
    total () {
      const total = this.subtotal + this.itbis
      return total > 0 ? total : 0
    }
  }
}
</script>
